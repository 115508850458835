@keyframes bgTableSuccessFadeOut {
	0%,
	5% {
		background-color: #fff;
	}
	5%,
	75% {
		background-color: #cffdc2;
	}

	100% {
		background-color: #fff;
	}
}

.bg-table-default {
	background-color: white;
}
.bg-table-success {
	animation: bgTableSuccessFadeOut 2s;
}
